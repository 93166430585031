var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c("b-col", { attrs: { cols: "12" } }, [
        _c("p", { staticClass: "primary fs-24 fw-bold text-center p-3 mb-0" }, [
          _vm._v("Cambiar contraseña"),
        ]),
      ]),
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "b-row",
            { staticClass: "justify-content-center pb-4" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12", sm: "10", md: "7", lg: "6" } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "px-3 px-md-4 px-lg-5 py-4 card-change-pass text-center",
                    },
                    [
                      _c("FormCambiarClave", {
                        on: {
                          terminarProceso: function ($event) {
                            return _vm.terminarProceso()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("ModalValidarOtp", {
        ref: "modalOtp",
        attrs: {
          tiempoReenvioCodigo: _vm.tiempoHabilitarReenvio,
          digitosCelular: _vm.digitosCelular,
          modal: _vm.modalOtp,
        },
        on: {
          reenviarCodigo: _vm.reenviarCodigo,
          sendForm: _vm.validarCodigo,
          useModal: _vm.useModal,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }